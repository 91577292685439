@font-face {
    font-family: 'Concord';
    src: url('../assets/fonts/Concord.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Concord-Light';
    src: url('../assets/fonts/Concord-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../assets/fonts/Futura.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} 

.divHome {
    max-width: 1280px !important;
}

.imgHome {
    max-width: '1280px';
}

.greenArrow,
.redTriangle,
.whiteArrow{
position: absolute;
width: auto;
height: 100%;
}

.greenArrow {
top: 45px;
left: 70px;
z-index: 1;
}
.whiteArrow {
top: 45px;
left: 70px;
z-index: 1;
}

.blackArrow {
top: 30px;
right: 0;
z-index: 1;
}
    

.redTriangle {
bottom: 35px;
right: 0; 
z-index: 1; 
}

.btnLearnMore {
position: absolute;
bottom: 65px;
right: 150px; 
z-index: 1; 
}

.container h1,
.container p {
position: relative; /* Para garantir que o texto fique acima das imagens */
z-index: 3; /* O texto está acima das imagens */
}



.circleBase {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 50%;
    margin: -50px;
    z-index: 1;
    text-align: center;
    font-size: 20px;
}
.circleBase p {
margin: 0;
padding: 0;

}

.circleGreen {
background-color: #007961; /* Verde escuro */
color: white;
}

.circleRed {
background-color: #9E2141; /* Vermelho escuro */
color: white;
border: 2px solid white;
}

.titleSingleImage {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media (max-width: 1024px) {
    .divHome {
        max-width: 1024px !important;
    }
}

@media (max-width: 767px) {
    .divHome {
        max-width: 767px !important;
    }
}


@media (max-width: 599px) {
    .divHome {
        max-width: 599px !important;
    }
}


@media (max-width: 480px) { 
    .divHome {
        max-width: 480px !important;
    }
}