@font-face {
    font-family: 'Concord';
    src: url('../assets/fonts/Concord.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../assets/fonts/Futura.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Concord';
    font-weight: bold;
} 